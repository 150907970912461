// Avter.js
import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './style.css';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

function Avter() {
  
  const [accessToken, setAccessToken] = useState('');
  const [generatedToken, setGeneratedToken] = useState('');
  const [hasMobileLayout, setHasMobileLayout] = useState(false);
  const [pageName, setPageName] = useState('ReportSection1'); // Default pageName
  const rolename = localStorage.getItem('roleName');
  let username = localStorage.getItem('employeeName');
  const isMobile = window.innerWidth <= 600;
  const layoutType = isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Custom;
  let roles;
  let createdby = localStorage.getItem('employeeId');
  const [lastApiHitTime, setLastApiHitTime] = useState(0);
  const [showIframe, setShowIframe] = useState(false); // State variable to track whether to show iframe

  const generatePowerBIToken = (accessToken) => {
    if (username === 'Tarang Goyal') {
      roles = ['owner'];
    } else if (username === "SUBHANKAR NATH" || username === 'Hardik Bhatt') {
      roles = ['divisionalhead'];
    } 
    else if (username === "Alok Jha") {
      username="Tarang Goyal"
      roles = ['owner'];
    }else if (username==="Vidya Dahatonde" || username ==="Surya Prakash Singh"  || username==="Akshay Singh" ||  username==="Meenakshi Jain"){
      roles=["Teamlead"]
    }

     else if (rolename === 'Recruiter') {
      roles = ['Recruiter'];
    } else if( rolename==="Team Lead"){
      username=localStorage.getItem("employeeName")
      roles=["Team Lead"]
      
    }
    else if (username === 'Abhishek Pawar' || username ==="Narendra Pal") {
      roles = ['headofbranch'];
    } else if (username === 'Prachi Mane' || username ==='AMITABH KAPOOR' || username==="Vibhas Kumar") {
      roles = ['BD'];
      
      username=localStorage.getItem('employeeName');
    
    

    }else if (username==="Akshay Singh" || username==="Mansi Naik" || username==="Shubhangi Dabholkar"  || username==="Sri Lakshmi"){
            roles=["Managers"]
            
    }
    

    
    else if(username==="Ravi M"){
      roles=["International"]
    }
    else if(username==="sudhir.k"){
      username="Abhishek Pawar"
      roles=['headofbranch']
    }
     else if (username === 'Archana Sharma' || username === 'Shalaka Gholap' || username === 'Avtar Sahani' || username === 'Poonam Sakharkar' || username === "Savita Kudale" || username === "Sandeep Goyal") {
      roles = ['owner'];
      username = "Tarang Goyal";
    } else if (rolename === 'Auditor') {
      roles = ['owner'];
      username = "Tarang Goyal";
      console.log(username)
    } else if (username === 'Sandeep Divekar') {
      roles = ['divisionalhead2'];
    } else if (username === 'Dharmraj Yadav') {
      roles = ['reportingmanagername'];
      username = "shyamvir sikarwar";

      
    } else if(username==="Ashok Dash"){
      roles=["AshokDash"]
      
    } else if (username==="Shyamvir Sikarwar"){
      roles=['AshokDash']
    }
          
    else {
      roles = ['reportingmanagername'];
    }
   
    const requestBodyToken = {
      datasets: [
        {
          id: "bfb8ebd5-6f1f-4a6d-a636-7064c4163be0",
        },
      ],
      reports: [
        {
          id: "9984bf78-d60d-4820-b67e-28e11f07c363",
        },
      ],
      identities: [
        {
          username: username,
          roles: roles,
          datasets: ["bfb8ebd5-6f1f-4a6d-a636-7064c4163be0"],
        },
      ]
    };

    fetch("https://api.powerbi.com/v1.0/myorg/GenerateToken", {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBodyToken),
    })
      .then((response) => response.json())
      .then((tokenResponse) => {
        const generatedToken = tokenResponse.token;
        setGeneratedToken(generatedToken);
        // Move checkMobileLayout call here
        checkMobileLayout(generatedToken);
      })
      .catch((error) => {
        console.error('Error generating Power BI token:', error);
      });
  };

  const checkMobileLayout = (accessToken) => {
    const config = {
      type: 'report',
      id: '9984bf78-d60d-4820-b67e-28e11f07c363',
      embedUrl: `https://app.powerbi.com/reportEmbed?reportId=9984bf78-d60d-4820-b67e-28e11f07c363&groupId=cea6d89a-3938-45e5-be31-0593f0738e42&pageName=${pageName}`,
      accessToken: accessToken,
      tokenType: models.TokenType.Embed,
    };

    const powerbi = new models.Service(config);

    powerbi.getReport().then((report) => {
      report.getPages().then((pages) => {
        const mobileLayout = pages[3].hasLayout(models.LayoutType.MobilePortrait);
        console.log('Mobile Layout:', mobileLayout);
        setHasMobileLayout(mobileLayout);
      });
    });
  };

  useEffect(() => {
    fetch('https://ap2-80fc.onrender.com/get_access_token')
      .then((response) => response.text())
      .then((token) => {
        const startIndex = token.indexOf('eyJ');
        logApiHit();

        if (startIndex !== -1) {
          const cleanedToken = token.substring(startIndex);
          setAccessToken(cleanedToken);
          generatePowerBIToken(cleanedToken);
        } else {
          console.error('Access token not found in the response');
        }
      })
      .catch((error) => {
        console.error('Error fetching access token:', error);
      });
  }, []); // Added empty dependency array to address the useEffect warning

  const logApiHit = () => {
    const currentTime = new Date().getTime();
    const fiveSeconds = 5000;

    if (currentTime - lastApiHitTime < fiveSeconds) {
      console.log('API hit within 5 seconds. Ignoring.');
      return;
    }
   
  
    const hitData = {
      username: username,
      timestamp: new Date().toISOString(),
      createdby: createdby,
      url: "https://ap2-80fc.onrender.com/get_access_token",
    };

    setLastApiHitTime(currentTime);

    fetch('https://usertraffic.onrender.com/record_hit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(hitData),
    })
      .then((response) => response.json())
      .then((hitResponse) => {
        console.log('Hit Data:', JSON.stringify(hitResponse));
        // Handle the response if needed
      })
      .catch((error) => {
        console.error('Error recording hit:', error);
      });
  };

  const changePage = (newPageName) => {
    setPageName(newPageName);
    // Hide iframe when other report buttons are clicked
    setShowIframe(false);
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleButtonClick = (url) => {
    // Show iframe when "Current Active Employees" button is clicked
    if (url === 'http://127.0.0.1:8000/candidates/') {
      setShowIframe(true);
    } else {
      setShowIframe(false);
      // Change page only if it's not the "Current Active Employees" button
      const pageName = url.split('changePage(')[1].split("'")[1];
      changePage(pageName);
    }

    // Pass employeeId to the iframe URL
    const employeeId = localStorage.getItem('employeeId');
    const iframeUrl = `${url}?employeeId=${employeeId}`;
    const iframe = document.getElementById('myIframe');
    if (iframe) {
      iframe.src = iframeUrl;
    }
  };


  return (
    <div className={`App ${isSidebarOpen ? '' : 'sidebar-closed'}`} >
      <div className={`Sidebar ${isSidebarOpen ? '' : 'sidebar-closed'}`} style={{ position: "relative" }}>

        <div className="ButtonsContainer">
          {(rolename === 'Recruiter') && (
            <>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection')}>DateWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection87586b127ec8d300458e')}>ClientWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionda25c5534482e74c16e7')}>RecruiterWise</button>
              {/* <button className="ButtonContainer" onClick={() => changePage('ReportSection6fa8c11601000c6d2400')}>RecruiterWise</button> */}
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionb34fadc72b12850bf0a7')}>Data Audit</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection277c03a8bc4898d75588')}>Employee Code Blank</button>
              {/* <button className="ButtonContainer" onClick={() => handleButtonClick('http://127.0.0.1:8000/candidates/')}>Current Active Employees</button> */}

            </>
            
          )}
          {(rolename === 'Admin') && (
            <>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection')}>DateWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection87586b127ec8d300458e')}>ClientWise</button>
              {/* <button className="ButtonContainer" onClick={() => changePage('ReportSection3ae51c867008f997d846')}>Untouched Requisitions</button> */}
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionda25c5534482e74c16e7')}>RecruiterWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectiona55e25db8d800980801d')}>SalesReport</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionb34fadc72b12850bf0a7')}>Data Audit</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection200763ab3604a917928b')}>Overall Employees Report</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection5d673b7699368b4a1614')}>BUDGET</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection213523b1c135901194bc')}>Outstation or Local</button>
              {/* <button className="ButtonContainer" onClick={() => handleButtonClick('http://127.0.0.1:8000/candidates/')}>Current Active Employees</button>
              <button className="ButtonContainer" onClick={() => handleButtonClick('http://127.0.0.1:8000/candidates/')}>BUDGET</button> */}
              <button className="ButtonContainer" onClick={() => changePage('ReportSection1120185f9b0ecde50209')}>BUDGETARY ANALYSIS</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection277c03a8bc4898d75588')}>Employee Code Blank</button>
            </>
          )}
          {(rolename === 'Auditor') && (
            <>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection')}>DateWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection87586b127ec8d300458e')}>ClientWise</button>
              {/* <button className="ButtonContainer" onClick={() => changePage('ReportSection3ae51c867008f997d846')}>Untouched Requisitions</button> */}
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionda25c5534482e74c16e7')}>RecruiterWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectiona55e25db8d800980801d')}>SalesReport</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionb34fadc72b12850bf0a7')}>Data Audit</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection200763ab3604a917928b')}>Overall Employees Report</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection277c03a8bc4898d75588')}>Employee Code Blank</button>
              

            </>
          )}
          {(rolename === 'Manager' || rolename==="DivisionalHead" || rolename==="Business Development") && (
            <>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection')}>DateWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection87586b127ec8d300458e')}>ClientWise</button>
              {/* <button className="ButtonContainer" onClick={() => changePage('ReportSection3ae51c867008f997d846')}>Untouched Requisitions</button> */}
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionda25c5534482e74c16e7')}>RecruiterWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectiona55e25db8d800980801d')}>SalesReport</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionb34fadc72b12850bf0a7')}>Data Audit</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection277c03a8bc4898d75588')}>Employee Code Blank</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection213523b1c135901194bc')}>Outstation or Local</button>
              

              {/* <button className="ButtonContainer" onClick={() => changePage('ReportSection0bad6c2dc3be98a8da40')}>Overall Employees Report</button> */}
            </>
          )}
          {/* {(rolename !== 'Recruiter') && (
            <>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection')}>DateWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection99387c616138ad2eeea1')}>ClientWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection3ae51c867008f997d846')}>Untouched Requisitions</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSection6fa8c11601000c6d2400')}>RecruiterWise</button>
              <button className="ButtonContainer" onClick={() => changePage('ReportSectionbd67f8ee81ea0a9c1e49')}>SalesReport</button>
            </>
          )} */}

        </div>

        <button className="ToggleSidebarButton" onClick={toggleSidebar} style={{ position: "absolute", top: "200px", right: "-20px", zIndex: "" }}>
          {isSidebarOpen ? <ArrowCircleLeftOutlinedIcon /> : <ArrowCircleRightOutlinedIcon />}
        </button>
      </div>
        
      <div className="ReportContainer">
  {showIframe ? (
    <iframe
      id="myIframe"
      title="URL Renderer"
      style={{ width: '100%', height: '1500px', border: '1px solid #ccc' }}
      src="http://127.0.0.1:8000/candidates"
    ></iframe>
  ) : (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        id: '9984bf78-d60d-4820-b67e-28e11f07c363',
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=9984bf78-d60d-4820-b67e-28e11f07c363&groupId=cea6d89a-3938-45e5-be31-0593f0738e42&pageName=${pageName}`,
        accessToken: generatedToken,
        tokenType: models.TokenType.Embed,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          background: isMobile ? models.BackgroundType.Transparent : models.BackgroundType.Default,
          layoutType: layoutType,
          
        },
        
      }}
      eventHandlers={
        new Map([
          ['loaded', function () {
            console.log('Report loaded');
          }],
          ['rendered', function () {
            console.log('Report rendered');
          }],
          ['error', function (event) {
            console.log(event.detail);
          }],
          ['visualClicked', () => console.log('Visual clicked')],
          ['pageChanged', (event) => console.log(event)],
        ])
      }
      cssClassName={'ReportClass'}
    />
  )}
</div>
     
        
    </div>
  );
  
}

export default Avter;